html,
body {
    
    margin: 0;
    padding: 0;
}
html{
    height: 100%;
    width: 100%;
}

body{
    overflow-x: hidden;
}

/* Force table to not be like tables anymore */
/* Smartphones (portrait and landscape) ----------- */
@media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    th,
    td,
    tr {
        display: contents;
    }
}

.sv-footer{
    display: flex;
}
@-ms-viewport {
    width: device-width;
    height: device-height;
}
